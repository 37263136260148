import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from "axios"
import { LangContext } from "../context/langContext"

export const TextContext = createContext({ text:[] });

const TextContextProvider = ({ children }) => {
  
  const { lang } = useContext(LangContext);

  const [text, setText] = useState([]);

  useEffect(() => {
    axios.get(`https://dashboard.almootamayezoon.com/index.php/examples/getlangauge/${lang}`)
    .then(res => {
      setText(res.data)
    })
  }, []);

  useEffect(() => {
    axios.get(`https://dashboard.almootamayezoon.com/index.php/examples/getlangauge/${lang}`)
    .then(res => {
      setText(res.data)
    })
  }, [lang]);



	return (
		<TextContext.Provider value={{ text }}>
			{children}
		</TextContext.Provider>
	);
}
export default TextContextProvider;