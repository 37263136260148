import React, { createContext, useState, useEffect } from 'react';
import axios from "axios"

export const ProductsContext = createContext({ categories:[], products:[], isLoading:null, changeActiveCatId:{} });

const ProductsContextProvider = ({ children }) => {

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [state, setState] = useState({
    activeCatId: 1,
    isLoading: true
  });
  const { activeCatId, isLoading } = state;

  useEffect(() => {
    async function fetchData() {
      const [catRes, prodRes] = await Promise.all([
        axios.get(`https://dashboard.almootamayezoon.com/index.php/examples/getcategories`),
        axios.get(`https://dashboard.almootamayezoon.com/index.php/examples/getproducts/1`)
      ])
      setCategories(catRes.data)
      setProducts(prodRes.data)
      setState({...state, isLoading:false})
    }
    fetchData();
  }, []);

  const changeActiveCatId = id => {
    setProducts([]);
    setState({
      activeCatId: id,
      isLoading: true
    })
  }

  useEffect(() => {
    axios.get(`https://dashboard.almootamayezoon.com/index.php/examples/getproducts/${activeCatId}`)
      .then(res => {
        setProducts(res.data)
        setState({...state, isLoading: false})
      })
  }, [activeCatId]);

	return (
		<ProductsContext.Provider value={{ categories, products, activeCatId, isLoading, changeActiveCatId }}>
			{children}
		</ProductsContext.Provider>
	);
}
export default ProductsContextProvider;