/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'bootstrap/dist/css/bootstrap.min.css';
import "./src/styles/global.scss";
import "./src/styles/xxl.scss";
import "./src/styles/xl.scss";
import "./src/styles/x.scss";
import "./src/styles/lg.scss";
import "./src/styles/md.scss";
import "./src/styles/sm.scss";
import "./src/styles/xs.scss";
import "./src/styles/modal.scss";
import "hamburgers/_sass/hamburgers/hamburgers.scss";


import React from "react"
import LangContextProvider from './src/context/langContext';
import ProductsContextProvider from './src/context/productsContext';
import TextContextProvider from './src/context/textContext';
import ReactNotifications from 'react-notifications-component';

export const wrapRootElement = ({ element }) => (
  <LangContextProvider>
    <ProductsContextProvider>
      <TextContextProvider>
        <ReactNotifications />
        {element}
      </TextContextProvider>
    </ProductsContextProvider>
  </LangContextProvider>
)