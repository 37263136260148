import React, { createContext, useState, useEffect } from 'react';

export const LangContext = createContext({ lang: 'en', toggleLang: {} });

const LangContextProvider = ({ children }) => {

	const [lang, setLang] = useState('en');

	useEffect(() => {
		const localLang = localStorage.getItem('lang')
		if(localLang){
			setLang(localLang)
		}
		else{
			setLang('en')
		}
	}, []);

	const toggleLang = () => {
		if (lang === 'ar') {
			setLang('en')
			localStorage.setItem('lang', 'en')
		} else {
			setLang('ar')
			localStorage.setItem('lang', 'ar')
		}
	}

	return (
		<LangContext.Provider value={{ lang, toggleLang }}>
			{children}
		</LangContext.Provider>
	);
}
export default LangContextProvider;